import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import Store from './redux/store'
import { AppContextProvider } from './context/app-context'
import { AuthProvider } from 'context/auth-provider'
import { LanguageProvider } from './context/language-provider'
import { SitesProvider } from './context/sites-provider'
import { QuotesProvider } from 'context/quotes-context'
import { ContentfulProvider } from './context/contentful-provider'
import CartProvider from 'context/cart-provider'
import CurrencyProvider from 'context/currency-context'
import ProductListProvider from 'context/product-list-context'

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://05d620568a59de82a2d5e75a3115a8c2@o461773.ingest.sentry.io/4506184036646912",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={Store}>
        <AuthProvider>
            <AppContextProvider>
                <SitesProvider>
                    <CartProvider>
                        <CurrencyProvider>
                            <QuotesProvider>
                                <LanguageProvider>
                                    <ContentfulProvider>
                                        <ProductListProvider>
                                            <App />
                                        </ProductListProvider>
                                    </ContentfulProvider>
                                </LanguageProvider>
                            </QuotesProvider>
                        </CurrencyProvider>
                    </CartProvider>
                </SitesProvider>
            </AppContextProvider>
        </AuthProvider>
    </Provider>
)
